import React from 'react';
import Article from '../components/FeaturedArticle/index'
import Layout from "../components/layout"
import SEO from '../components/seo';

const Post = (props) => {




	const { pageContext } = props;

	const { title, content, featuredImage, slug } = pageContext;

	const noArticleHeaderImage = featuredImage ? null : true

	return (
		<Layout article headerImage={featuredImage} noArticleHeaderImage={noArticleHeaderImage}>
			<SEO title={title} image={featuredImage} url={`${process.env.GATSBY_WEBSITE_DOMAIN}/${slug}/`} />
			<Article title={title} content={content} headerImage={featuredImage} noArticleHeaderImage={noArticleHeaderImage}/>
		</Layout>
	);
};

export default Post;
